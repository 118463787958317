import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
import fileListConfig from 'modules/base/views/fileListConfig'
class listConfig {
  protected vm?: Vue
  protected listKey?: string
  protected standardFileListConfig?: EagleListConfigInterface

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.standardFileListConfig = fileListConfig.get(vm, listKey)
    this.vm = vm
    this.listKey = listKey

    return {
      search: this.standardFileListConfig.search,
      filter: this.standardFileListConfig.filter,
      selectedData: this.standardFileListConfig.selectedData,
      sort: this.standardFileListConfig.sort,
      table: this.standardFileListConfig.table,
    }
  }
}

export default new listConfig()
